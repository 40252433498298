<template>
  <div 
    class="not-btn main-btn rounded-pill d-flex" 
    :class="colorBtn">
    <svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg" class="me-2">
    <circle cx="2.5" cy="2.5" r="2.5" fill="white"/>
    </svg>
    <p class="m-0">
      {{buttonText}}
    </p>
</div>
</template>

<script>

export default {
  name: 'NotButton',
  props: {
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'pink-btn'
    },
  }
}
</script>