<template>
    <div style="padding: 0 10px; text-align:center; background-color: #000000; color: #fff; font-size: 14px; font-weight: 600;">
        <span style="font-weight: 300;">Listo para la faena, pero sin examen médico? Reduce tu tiempo de acreditación
        </span>
        <br class="d-block d-md-none">
        <a target="_blank" href="https://examine.cl/?utm_source=mineria_mine-class&utm_campaign=agenda_tu_examen_25&utm_content=banner_sitio" style="background-color: #EF574F; padding: 10px 20px; border-radius: 30px; color: #FFFFFF; text-transform: uppercase; font-weight: 600; margin-top: 5px; margin-bottom: 5px; display: inline-block; font-size: 12px; margin-right: 10px; margin-left: 10px;">Gestiona tu examen</a>
    </div>
</template>

<script>
export default {
    name: 'BannerTop'
}
</script>