<template>
  <div id="carouselServicios" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselServicios" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselServicios" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselServicios" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="servicio-box">
          <img src="../assets/img/servicio-3.jpg" alt="" class="img-fluid">
        </div>
        <h3 class="red-font py-4">Desarrollo de contenido personalizado</h3>
        <p class="raleway-15 white-font">
          Desarrollamos contenido a la medida con nuestra <span class="bold">metodología de Video Learning e Inteligencia Artificial</span>, probado por miles de personas. Creamos y/o actualizamos cursos de las empresas según los requerimientos que tengan. Mediante IA podemos generar este contenido mucho más rápido.
        </p>
        <div class="not-buttons">
          <NotButton
            :buttonText="'Rapidez en la creación del contenido'"
            :colorBtn="'red-btn'"
          />
          <NotButton
            :buttonText="'Rapidez en la creación del contenido'"
            :colorBtn="'white-btn'"
          />
          <NotButton
            :buttonText="'Claridad y efectividad'"
            :colorBtn="'degrade-not-btn'"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="servicio-box">
          <img src="../assets/img/servicio-4.jpg" alt="" class="img-fluid">
        </div>
        <h3 class="red-font py-4">Aprende de minería</h3>
        <p class="raleway-15 white-font">
          Más de 30 cursos con contenido minero. Disponemos mas de 100 horas de <span class="bold">Video Clases</span> con contenido que sirve para cualquier persona. Contamos con diversos cursos, que van desde "Minería para no Mineros" hasta cursos específicos como "Cierre de faenas mineras".
        </p>
        <div class="not-buttons">
          <NotButton
            :buttonText="'Diversidad de cursos'"
            :colorBtn="'red-btn'"
          />
          <NotButton
            :buttonText="'Potencia las habilidades de tus colaboradores'"
            :colorBtn="'white-btn'"
          />
        </div>
      </div>
      <div class="carousel-item">
        <div class="servicio-box">
          <img src="../assets/img/servicio-5.jpg" alt="" class="img-fluid">
        </div>
        <h3 class="red-font py-4">Cursos Codelco VP</h3>
        <p class="raleway-15 white-font">
          Somos una <span class="bold">Otec acreditada por Codelco</span> para realizar los cursos estandarizados para la Vicepresidencia de Proyectos de Codelco. Coordinamos y realizamos las <span class="bold">inducciones para habilitar a los trabajadores</span>, permitiendo el ingreso de manera más rápida a Faena. Las inducciones las realizamos de manera virtual por Zoom con relatores con basta experiencia en el rubro.
        </p>
        <div class="not-buttons">
          <NotButton
            :buttonText="'Certificados inmediatos'"
            :colorBtn="'red-btn'"
          />
          <NotButton
            :buttonText="'Apoyo en el proceso de inducción'"
            :colorBtn="'white-btn'"
          />
          <NotButton
            :buttonText="'Coordinación de cursos en menos de 24 hrs'"
            :colorBtn="'degrade-not-btn'"
          />
        </div>
      </div>
    </div>
</div>
</template>

<script>
import NotButton from './NotButton.vue'

export default {
 name: 'CarouselServicios',
 components: {
  NotButton,
 }
}
</script>