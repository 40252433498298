<template>
  <div class="fixed-top-mobile">
    <BannerTop />
    <div class="pre-navbar container-fluid">
      <MainButton :colorBtn="'white-btn'" :buttonText="'Solicitar demo'" class="me-3"
        :router="'https://share-eu1.hsforms.com/1mIQmbipMQhSSWNdCSVNngAf2fjz'" />

      <div class="dropdown">
        <MainButton :colorBtn="'white-btn'" :buttonText="'Acceso a plataforma'" class="dropdown-toggle"
          data-bs-toggle="dropdown" />
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" href="https://mine-class.com/wp-login.php" target="_blank">Mine-Class</a></li>
          <li><a class="dropdown-item" href="https://codelco.newminer.io/wp-login.php" target="_blank">Codelco
              Corporativo</a></li>
          <li><a class="dropdown-item" href="https://vppcodelco.newminer.io/wp-login.php" target="_blank">Codelco
              VP</a></li>
          <li><a class="dropdown-item" href="https://otec.mine-class.com/" target="_blank">Otec</a></li>
        </ul>
      </div>
      <!-- <MainButton
        :colorBtn="'degrade-btn'"
        :buttonText="'Verificar certificado'"
      /> -->
      <!-- <a href="https://mine-class.com/wp-login.php" target="_blank" class="raleway-15 white-font ms-3">Ingresar<img
          src="../assets/icons/user-icon.svg" alt="" class="ms-lg-2"></a> -->
    </div>
    <div class="">
      <nav class="navbar navbar-expand-lg">
        <div class="container-fluid container-navbar">
          <a class="navbar-brand" href="#"><img src="../assets/logos/logo-navbar.png" alt=""></a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div ref="navbarCollapse" class="collapse navbar-collapse justify-content-lg-end justify-content-center"
            id="navbarNav">
            <ul class="navbar-nav text-center raleway-15 py-lg-0 py-4">
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{ name: 'home', hash: '#' + 'beneficios' }"
                  @click="closeMenu">
                  ¿Qué es Mine Class?
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{ name: 'home', hash: '#' + 'servicios' }"
                  @click="closeMenu">
                  Servicios
                </router-link>

              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{ name: 'home', hash: '#' + 'clientes' }"
                  @click="closeMenu">
                  Nuestros clientes
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{ name: 'home', hash: '#' + 'dudas' }"
                  @click="closeMenu">
                  ¿Tienes dudas?
                </router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link p-16" aria-current="page" :to="{ name: 'home', hash: '#' + 'dudas' }"
                  @click="closeMenu">
                  Blog Mine Class
                </router-link>
              </li>
              <li class="d-lg-none pre-navbar-navbar">
                <MainButton :colorBtn="'white-btn'" :buttonText="'Solicitar demo'" class="me-lg-3 pre-navbar-btn-mobile"
                  :router="'https://share-eu1.hsforms.com/1mIQmbipMQhSSWNdCSVNngAf2fjz'" />
              </li>
              <!-- <li class="d-lg-none">
                <MainButton
                  :colorBtn="'degrade-btn'"
                  :buttonText="'Acreditación a faena'"
                  class="mb-4"
                />
              </li> -->
              <li class="d-lg-none">
                <div class="dropdown mb-4">
                  <button class="btn rounded-pill purple-btn dropdown-toggle" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    Acceso a plataforma
                  </button>
                  <ul class="dropdown-menu">
                    <li><a class="dropdown-item" href="https://mine-class.com/wp-login.php" target="_blank">Mine-Class</a></li>
                    <li><a class="dropdown-item" href="https://codelco.newminer.io/wp-login.php" target="_blank">Codelco
                        Corporativo</a></li>
                    <li><a class="dropdown-item" href="https://vppcodelco.newminer.io/wp-login.php"
                        target="_blank">Codelco
                        VP</a></li>
                    <li><a class="dropdown-item" href="https://otec.mine-class.com/" target="_blank">Otec</a></li>
                  </ul>
                </div>
              </li>
              <!-- <li class="d-lg-none bg-purple">
                <a href="https://mine-class.com/wp-login.php" target="_blank"
                  class="raleway-15 white-font ms-3">Ingresar<img src="../assets/icons/user-icon.svg" alt=""
                    class="ms-2"></a>
              </li> -->
            </ul>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
import MainButton from './MainButton.vue'
import BannerTop from './BannerTop.vue'

export default {
  name: 'NavBar',
  components: {
    MainButton,
    BannerTop,
  },
  methods: {
    closeMenu() {
      const navbarCollapse = this.$refs.navbarCollapse;
      if (navbarCollapse.classList.contains('show')) {
        navbarCollapse.classList.remove('show'); // Cierra el menú
      }
    },
  },
}
</script> 