<template>
  <div>
    <div class="accordion" id="accordionExample">
      <div 
        class="accordion-item"
        v-for="(faq, index) in faqs"
        :key="index"
      >
        <h4 class="accordion-header text-center">
          <button 
            class="accordion-button collapsed raleway-20 bold text-center" 
            type="button" 
            :data-bs-toggle="'collapse'"
            :data-bs-target="'#collapse' + index"
            :aria-expanded="false"
            :aria-controls="'collapse' + index">
            <div class="titulo-pregunta">{{ faq.pregunta }}</div>
          </button>
        </h4>
        <div 
          :id="'collapse' + index"
          class="accordion-collapse collapse"
          data-bs-parent="#accordionExample"
        >
          <div class="accordion-body raleway-15 mb-3 pt-0">
            <p class="mb-0 text-center" v-html="faq.respuesta">
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: "AccordionDudas",
  computed: {
   ...mapState(['faqs']),
  }
};
</script>

