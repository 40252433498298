<template>
  <div id="carouselTestimonios" class="carousel carousel-dark">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselTestimonios" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselTestimonios" data-bs-slide-to="1" aria-label="Slide 2"></button>
    </div>
    <div class="carousel-inner">
      <div 
        v-for="(video, index) in videos"
        :key="index"
        :class="['carousel-item', { active: index === 0 }]"
      >
        <div class="video-cont">
          <div class="testimonio-img">
            <div class="box-flex">
              <img :src="require(`@/assets/img/${video.img}`)" alt="">
              <button 
                type="button" 
                class="btn" 
                data-bs-toggle="modal" 
                :data-bs-target="`#Modal${index}`"
                @click="loadVideo(index)"
              >
                <img src="../assets/icons/play-icon.svg" alt="boton play">
              </button>
            </div>
          </div>
          
          <!-- Modal -->
          <div class="modal fade" :id="`Modal${index}`" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <div class="video-responsive">
                    <iframe
                      v-if="index === activeVideoIndex" 
                      width="560" 
                      height="315" 
                      :src="video.url" 
                      title="YouTube video player" 
                      frameborder="0" 
                      allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen>
                    </iframe>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="video-text text-start">
          <span class="d-flex align-items-center"><img src="../assets/icons/arrow.svg" alt=""><h4>{{video.nombre}}</h4></span>
          <p class="raleway-15">
            {{ video.cargo }}
          </p>
        </div>
      </div>
    </div>
    <!--<div class="buttons">
      <button 
        v-if="activeIndex !== 0"
        class="carousel-control-prev" 
        type="button" 
        data-bs-target="#carouselTestimonios" 
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        v-if="activeIndex !== videos.length - 1" 
        class="carousel-control-next" 
        type="button" 
        data-bs-target="#carouselTestimonios" 
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>-->
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: "CarouselTestimonios",
  data() {
    return {
      activeIndex: 0, // Índice de la slide activa
    };
  },
  computed: {
    ...mapState(["videos", "activeVideoIndex"]),
  },
  methods:{
    ...mapMutations(["setActiveVideo"]),
    loadVideo(index) {
      this.setActiveVideo(index); // Actualiza el video activo en Vuex
    },
  },
  mounted() {
    // Detectar cambios en el índice de la slide activa
    const carousel = document.getElementById("carouselTestimonios");
    carousel.addEventListener("slid.bs.carousel", () => {
      const activeItem = carousel.querySelector(".carousel-item.active");
      this.activeIndex = Array.from(carousel.children[0].children).indexOf(activeItem);
    });
  },
};
</script>
