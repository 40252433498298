<template>
  <div id="carouselExample" class="carousel slide container" data-bs-ride="carousel" data-bs-wrap="true">
    <!-- Indicadores -->
    <div class="carousel-indicators">
      <button
        v-for="(slide, index) in slides"
        :key="index"
        type="button"
        data-bs-target="#carouselExample"
        :data-bs-slide-to="index"
        :class="{ active: index === 0 }"
        :aria-current="index === 0 ? 'true' : undefined"
        :aria-label="`Slide ${index + 1}`"
      ></button>
    </div>
    <!-- Contenido del carrusel -->
    <div class="carousel-inner">
      <div
        v-for="(slide, index) in slides"
        :key="index"
        :class="['carousel-item', { active: index === 0 }]"
        data-bs-interval="3000"
      >
        <div class="row">
          <div class="col-lg-5 slide-text">
            <h1>{{ slide.title }}<span class="regular">{{ slide.subtitle }}</span></h1>
            <p class="raleway-20">{{ slide.description }}</p>
            <ArrowButton
              :colorBtn="slide.buttonColor"
              :buttonText="slide.buttonText"
              :arrowColor="slide.arrowColor"
              :router="slide.btnLink"
              class="text-start"
            />
          </div>
          <div class="col-lg-7" :class="slide.imageClass">
            <img :src="require(`@/assets/img/${slide.img}`)" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowButton from '@/components/ArrowButton.vue'
import { mapState } from 'vuex'

export default {
  components: {
    ArrowButton,
  },
  computed: {
    ...mapState(['slides']),
  },
  methods: {

  }
};
</script>