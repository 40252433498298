<template>
  <div class="home">
    <header>
      <CarouselHeader/>
    </header>
    <div id="beneficios" class="container">
      <div class="degrade-box raleway-15">
        <div class="row">
          <div class="col-lg-4">
            <img src="@/assets/icons/beneficio-1.svg" alt="">
            <p>Ofrecemos un <span class="bold">servicio a la medida</span> para empresas mineras y contratistas de la minería.</p>
          </div>
          <div class="col-lg-4">
            <img src="@/assets/icons/beneficio-2.svg" alt="">
            <p>Aseguramos la identidad de los alumnos con su ingreso a través de <span class="bold">reconocimiento facial.</span></p>
          </div>
          <div class="col-lg-4 sin-borde">
            <img src="@/assets/icons/beneficio-3.svg" alt="">
            <p>Ofrecemos un <span class="bold">Soporte en línea</span> continuo para alumnos durante sus estudios y evaluaciones.</p>
          </div>
        </div>
      </div>
    </div>
    <div id="focos" class="container text-center d-grid justify-items-center justify-content-center">
      <h2>
        Nos enfocamos en
      </h2>
      <hr class="h2-underline">
      <div class="row text-start">
        <div class="col-lg-6">
          <img src="../assets/img/focos-img.png" alt="tractor" class="tractor-img">
        </div>
        <div class="col-lg-6">
          <CardFocos
            :imagenFoco="'foco-1.jpg'"
            :tituloFoco="'Empresas Mineras'"
            :descripcionFoco="'Que buscan simplificar el proceso de inducción a trabajadores previo a la entrada a la faena.'"
          />
          <CardFocos
            :imagenFoco="'foco-2.jpg'"
            :tituloFoco="'Empresas Contratistas'"
            :descripcionFoco="'Del sector minero, que deben cumplir de manera eficiente con los requerimientos exigidos a sus trabajadores.'"
          />
          <CardFocos
            :imagenFoco="'foco-4.jpg'"
            :tituloFoco="'Capacitación con cobertura SENCE'"
            :descripcionFoco="'Que permite a las empresas acceder a beneficios exclusivos mientras invierten en la formación de sus trabajadores.'"
            :linkFoco="{
              text: 'Conoce más',
              url: 'https://share-eu1.hsforms.com/1F2l_cQlfTUG6AWGgNxeuUAf2fjz'
            }"
          />
        </div>
      </div>
    </div>
    <img src="../assets/bgs/purple-diagonal.svg" alt="" class="diagonal">
    <div id="servicios">
      <div class="container">
        <img src="../assets/bgs/formas-servicio.svg" alt="" class="formas-servicio">
        <img src="../assets/bgs/formas-servicio.svg" alt="" class="formas-servicio-chico">
        <div class="text-center d-grid">
          <h2 class="white-font">
            Nuestros servicios
          </h2>
          <hr class="h2-underline white mb-5">
        </div>
        <div class="row text-lg-start mt-5 align-items-center">
          <div class="col-lg-6">
            <div class="servicio-box">
              <img src="../assets/img/servicio-1.jpg" alt="" class="img-fluid">
            </div>
          </div>
          <div class="col-lg-6">
            <h3 class="red-font pb-4">Cursos de seguridad teórico práctico</h3>
            <p class="raleway-15 white-font">
              Inducciones que tienen el objetivo de enseñar y capacitar a las y los participantes en medidas de <span class="bold">seguridad y prevención de riesgos</span>, tanto a nivel teórico (conocimiento de normativas, principios de seguridad, análisis de riesgos) como a nivel práctico (uso de equipos de protección, simulaciones de situaciones de riesgo, protocolos de emergencia).
            </p>
            <div class="not-buttons">
              <NotButton
                :buttonText="'Prevención de accidentes'"
                :colorBtn="'red-btn'"
              />
              <NotButton
                :buttonText="'Desarrollo de habilidades prácticas'"
                :colorBtn="'white-btn'"
              />
              <NotButton
                :buttonText="'Cumplimiento normativo'"
                :colorBtn="'degrade-not-btn'"
              />
            </div>
          </div>
        </div>
        <div class="row text-lg-end mt-5 align-items-center">
          <div class="col-lg-6 order-lg-1">
            <div class="servicio-box">
              <img src="../assets/img/servicio-2.jpg" alt="" class="img-fluid">
            </div>
          </div>
          <div class="col-lg-6 order-lg-0">
            <h3 class="red-font pb-4">Campus LMS</h3>
            <p class="raleway-15 white-font">
               Es una <span class="bold">plataforma en línea</span> que facilita la gestión y administración de procesos de formación y educación. Permite a las empresas ofrecer cursos y programas de capacitación de manera estructurada y accesible.Creamos una plataforma a la medida de la empresa que permite alojar contenido o cursos ya existentes para sus trabajadores/as.
            </p>
            <div class="not-buttons justify-content-lg-end">
              <NotButton
                :buttonText="'Acceso flexible y a distancia'"
                :colorBtn="'red-btn'"
              />
              <NotButton
                :buttonText="'Organización centralizada'"
                :colorBtn="'white-btn'"
              />
              <NotButton
                :buttonText="'Cumplimiento normativo'"
                :colorBtn="'degrade-not-btn'"
              />
            </div>
          </div>
        </div>
        <!--Otros Servicios Desktop-->
        <div class="row text-start secondary-projects d-lg-flex d-none">
          <div class="col-lg-4">
            <div class="servicio-box">
              <img src="../assets/img/servicio-3.jpg" alt="" class="img-fluid">
            </div>
            <div class="box-texto">
              <h3 class="red-font py-4">Desarrollo de contenido personalizado</h3>
              <p class="raleway-15 white-font">
                Desarrollamos contenido a la medida con nuestra metodología de<span class="bold"> Video Learning e Inteligencia Artificial</span>, probado por miles de personas. Creamos y/o actualizamos cursos de las empresas según los requerimientos que tengan. Mediante IA podemos generar este contenido mucho más rápido.
              </p>
              <div class="not-buttons">
                <NotButton
                  :buttonText="'Rapidez en la creación del contenido'"
                  :colorBtn="'red-btn'"
                />
                <NotButton
                  :buttonText="'Rapidez en la creación del contenido'"
                  :colorBtn="'white-btn'"
                />
                <NotButton
                  :buttonText="'Claridad y efectividad'"
                  :colorBtn="'degrade-not-btn'"
                />
              </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="servicio-box">
              <img src="../assets/img/servicio-4.jpg" alt="" class="img-fluid">
            </div>
            <div class="box-texto">
              <h3 class="red-font py-4">Aprende de minería</h3>
              <p class="raleway-15 white-font">
                Más de 30 cursos con contenido minero. Disponemos más de 100 horas de <span class="bold">Video Clases</span> con contenido que sirve para cualquier persona. Contamos con diversos cursos, que van desde "Minería para no Mineros" hasta cursos específicos como "Cierre de faenas mineras".
              </p>
              <div class="not-buttons">
                <NotButton
                  :buttonText="'Diversidad de cursos'"
                  :colorBtn="'red-btn'"
                />
                <NotButton
                  :buttonText="'Potencia las habilidades de tus colaboradores'"
                  :colorBtn="'white-btn'"
                />
            </div>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="servicio-box">
              <img src="../assets/img/servicio-5.jpg" alt="" class="img-fluid">
            </div>
            <div class="box-texto">
              <h3 class="red-font py-4">Cursos Codelco VP</h3>
              <p class="raleway-15 white-font">
                Somos una <span class="bold">Otec acreditada por Codelco</span> para realizar los cursos estandarizados para la Vicepresidencia de Proyectos de Codelco. Coordinamos y realizamos las <span class="bold">inducciones para habilitar a los trabajadores</span>, permitiendo el ingreso de manera más rápida a Faena. Las inducciones las realizamos de manera virtual por Zoom con relatores con basta experiencia en el rubro.
              </p>
              <div class="not-buttons">
                <NotButton
                  :buttonText="'Certificados inmediatos'"
                  :colorBtn="'red-btn'"
                />
                <NotButton
                  :buttonText="'Apoyo en el proceso de inducción'"
                  :colorBtn="'white-btn'"
                />
                <NotButton
                  :buttonText="'Coordinación de cursos en menos de 24 hrs'"
                  :colorBtn="'degrade-not-btn'"
                />
              </div>
            </div>
          </div>
        </div>
        <!--Otros Servicios Mobile-->
        <CarouselServicios class="d-block d-lg-none"/>
      </div>
    </div>
    <img src="../assets/bgs/purple-diagonal.svg" alt="" class="diagonal invertir">
    <div id="cta">
      <img src="../assets/bgs/bg-cta.png" alt="" class="bg-cta">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h2 class="cta-text">
              Somos tu partner estratégico para <span class="regular">simplificar tus capacitaciones</span>
            </h2>
            <ArrowButton 
                :colorBtn="'red-btn'" 
                :buttonText="'Quiero una demo'" 
                :router="'https://share-eu1.hsforms.com/1mIQmbipMQhSSWNdCSVNngAf2fjz'"
                class="mt-4"
              />
          </div>
          <div class="col-md-6">
            <img src="../assets/img/cta-img.png" alt="" class="cta-img">
          </div>
        </div>  
      </div>
    </div>
    <div id="clientes">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 title-cont">
            <h2>Nuestros clientes</h2>
            <hr class="h2-underline mb-5">
          </div>
          <img src="../assets/bgs/formas-clientes.svg" alt="" class="formas-clientes">
          <div class="col-lg-7">
            <CarouselTestimonios/>
          </div>
        </div>
        <div class="otros-testimonios">
          <div class="row">
            <div class="col" v-for="(otro, index) in otros" :key="index">
              <div class="box-testimonio raleway-15">
                <p class="texto-testimonio">
                {{ otro.testimonio }}
                </p>
                <div>
                  <div class="d-flex align-items-center mb-1"><img src="../assets/icons/arrow.svg" alt="" class="me-2"><h4 class="raleway-15 bold m-0">{{otro.nombre}}</h4></div>
                  <p class="m-0">{{ otro.cargo }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="han-confiado">
        <div class="container">
          <img src="../assets/logos/acreditados-vp-codelco.png" alt="" class="acreditados">
          <div class="row row-1">
            <div class="col-lg-4 d-grid">
              <h2>Han confiado</h2>
              <hr class="h2-underline mb-5">
            </div>
            <div class="col-lg-8">
              <div class="row row-2">
                <div class="col-lg-3 col-12 logos-cont" v-for="(logo, index) in logos" :key="index">
                  <img :src="require(`@/assets/logos/logos_clientes/${logo}`)" alt="" class="img-fluid">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="dudas">
      <img src="../assets/bgs/formas-faqs-1.svg" alt="" class="formas-dudas-1">
      <img src="../assets/bgs/formas-faqs-2.svg" alt="" class="formas-dudas-2">
      <div class="text-center d-grid"> 
        <h2>
          ¿Tienes dudas?
        </h2>
        <hr class="h2-underline">
      </div>
      <AccordionDudas/>
    </div>
    <div id="contacto">
      <div class="purple-box"></div>
      <div class="container">
        <div class="box-form">
          <img src="../assets/bgs/formas-contacto.svg" alt="" class="formas-contacto">
          <div class="row">
            <div class="col-lg-6 text-start">
              <h2 class="white-font">Esperamos tu <span class="regular">mensaje</span></h2>
              <hr class="h2-underline">
              <img src="../assets/img/casco.png" alt="casco" class="casco-img">
            </div>
            <div class="col-lg-6">
              <!-- Aquí se cargará el formulario de HubSpot -->
              <div id="hubspot-form"></div>
            </div>
          </div>
        </div>
        <div class="row datos-contacto">
          <div class="col-lg-4">
            <img src="../assets/icons/mail.svg" alt="">
            <p class="raleway-20 bold"><a href="mailto:hola@mine-class.cl">hola@mine-class.cl</a></p>
          </div>
          <div class="col-lg-4">
            <img src="../assets/icons/pin.svg" alt="">
            <p class="raleway-20 bold">General Holley 2313, <br><span class="regular">Providencia, Santiago de Chile</span></p>
          </div>
          <div class="col-lg-4 no-border">
            <img src="../assets/icons/wsp.svg" alt="">
            <p class="raleway-20 bold">+56 9 8332 7013</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import CarouselHeader from '@/components/CarouselHeader.vue'
import CardFocos from '@/components/CardFocos.vue'
import NotButton from '@/components/NotButton.vue'
import ArrowButton from '@/components/ArrowButton.vue'
import CarouselTestimonios from '@/components/CarouselTestimonios.vue'
import AccordionDudas from '@/components/AccordionDudas.vue'
import CarouselServicios from '@/components/CarouselServicios.vue'
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  components: {
    CardFocos,
    CarouselHeader,
    NotButton,
    ArrowButton,
    CarouselTestimonios,
    AccordionDudas,
    CarouselServicios
  },
  computed: {
    ...mapState(['otros', 'logos']),
  },
  mounted() {
    // Cargar el script de HubSpot
    const script = document.createElement("script");
    script.src = "//js-eu1.hsforms.net/forms/embed/v2.js";
    script.charset = "utf-8";
    script.type = "text/javascript";
    script.onload = this.loadHubspotForm; // Cargar el formulario después de que el script esté listo
    document.body.appendChild(script);
  },
  methods: {
    loadHubspotForm() {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "25307711",
          formId: "f3aae417-42ba-4d66-aac1-5df97371fb51",
          target: "#hubspot-form", // Asegúrate de que coincida con el contenedor creado
        });
      } else {
        console.error("HubSpot script failed to load.");
      }
    },
  },
}
</script>