<template>
  <div class="card mb-3">
    <div class="row g-0">
      <div class="col-lg-4">
        <img :src="imageSrc" class="img-fluid rounded-start foco-img" alt="...">
      </div>
      <div class="col-lg-8">
        <div class="card-body">
          <div class="d-flex">
            <img src="../assets/icons/arrow.svg" alt="">
            <h5 class="card-title raleway-15 bold m-0">{{tituloFoco}}</h5>
          </div>
          <p class="card-text raleway-15 pt-2">{{ descripcionFoco }}</p>

          <ArrowButton :colorBtn="'purple-btn'" :router="linkFoco.url" :buttonText="linkFoco.text" v-if="linkFoco?.url" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowButton from './ArrowButton.vue';

export default {
  name: "CardFocos",
  components: {
    ArrowButton
  },
  props: {
    tituloFoco: {
      Type: String,
      Default: " Titulo Foco",
      required: true
    },
    descripcionFoco: {
      Type: String,
      Default: "Descripción",
      required: true
    },
    imagenFoco: {
      Type: String,
      Default: "foco-2.jpg",
      required: true
    },
    linkFoco: {
      Type: Object,
      Default: {},
      required: false
    }
  },
  computed: {
    imageSrc() {
      try {
        return require(`@/assets/img/${this.imagenFoco}`);
      } catch (e) {
        console.error(`Image not found: ${this.imagenFoco}`);
        return require('@/assets/img/foco-2.jpg'); // Imagen predeterminada
      }
    }
  }
};
</script>