<template>
  <button 
    type="button"
    class="btn main-btn rounded-pill" 
    :class="colorBtn">
    <!-- Verifica si la ruta es una URL externa -->
    <a
      v-if="isExternalLink"
      :href="router"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ buttonText }}
    </a>
    <router-link v-else :to="router">{{ buttonText }}</router-link>
    <svg class="ms-3" width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.517335 3.20626C3.99558 6.41301 7.47382 9.62731 10.9521 12.8341V10.163C7.47382 13.3698 3.99558 16.5841 0.517335 19.7908C-0.211168 20.4624 -0.132604 21.7752 0.517335 22.4619C1.2387 23.2239 2.31717 23.1334 3.04567 22.4619C6.52392 19.2551 10.0022 16.0408 13.4804 12.8341C14.1732 12.1927 14.1732 10.8044 13.4804 10.163C10.0022 6.95627 6.52392 3.74198 3.04567 0.535225C2.31717 -0.136306 1.23155 -0.219305 0.517335 0.535225C-0.132604 1.22185 -0.211168 2.52718 0.517335 3.20626Z" :fill="arrowColor"/>
    </svg>
  </button>
</template>

<script>

export default {
  name: 'ArrowButton',
  props: {
    router: {
      type: String,
    },
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'pink-btn'
    },
    arrowColor: {
      type: String,
      default: '#F05948'
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.router === 'string' && this.router.startsWith('http');
    }
  }
}
</script>