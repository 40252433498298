<template>
  <button 
    type="button"
    class="btn main-btn rounded-pill" 
    :class="colorBtn">
    <!-- Verifica si la ruta es una URL externa -->
    <a
      v-if="isExternalLink"
      :href="router"
      target="_blank"
      rel="noopener noreferrer"
    >
      {{ buttonText }}
    </a>
    <router-link v-else :to="router">{{ buttonText }}</router-link>
  </button>
</template>

<script>

export default {
  name: 'MainButton',
  props: {
    router: {
      type: [Object, String],
      default: "/",
    },
    buttonText: {
      type: String,
      default: 'Button'
    },
    colorBtn: {
      type: String,
      default: 'pink-btn'
    },
  },
  computed: {
    isExternalLink() {
      return typeof this.router === 'string' && this.router.startsWith('http');
    }
  }
}
</script>