<template>
  <div>
    <footer>
      <img src="../assets/bgs/formas-footer.svg" alt="" class="formas-footer">
      <div class="container-fluid footer-cont">
        <div class="row">
          <div class="col-lg-4">
            <img src="../assets/logos/logos_footer/logo-footer.svg" alt="">
            <p class="raleway-15 pt-5">
              Mine Class es líder en capacitación corporativa en la industria minera y construcción de Latinoamérica reconocida como una de las Top 100 Edtech de Latam el 2020.
            </p>
            <div class="d-flex logos-fila-2">
              <img src="../assets/logos/logos_footer/logo-latam-edtech.png" alt="" class="">
              <img src="../assets/logos/logos_footer/logo-lsqa.png" alt="" class="">
              <img src="../assets/logos/logos_footer/logo-sence.png" alt="">
            </div>
            <div class="d-flex iconos-rrss">
              <a href="https://www.facebook.com/mineclasscom/" target="_blank"><img src="../assets/icons/fb.svg" alt=""></a>
              <a href="https://www.instagram.com/mine_class/" target="_blank"><img src="../assets/icons/insta.svg" alt=""></a>
              <a href="https://www.linkedin.com/school/mine-class/" target="_blank"><img src="../assets/icons/linkedin.svg" alt=""></a>
              <a href="https://www.youtube.com/@Mine-class" target="_blank"><img src="../assets/icons/youtube.svg" alt=""></a>
            </div>
          </div>
          <div class="col-lg-3">
            <ul class="raleway-20">
              <li>
                <router-link class="" aria-current="page" :to="{name: 'home', hash: '#'+'beneficios'}">¿Qué es Mine Class?</router-link>
              </li>
              <li class="">
                <router-link class="" aria-current="page" :to="{name: 'home', hash: '#'+'servicios'}">Servicios</router-link>
                
              </li>
              <li class="">
                <router-link class="" aria-current="page" :to="{name: 'home', hash: '#'+'clientes'}">Nuestros clientes</router-link>
                
              </li>
              <li class="">
                <router-link class="" aria-current="page" :to="{name: 'home', hash: '#'+'dudas'}">¿Tienes dudas?</router-link>
              </li>
              <li class="sin-borde">
                <router-link class="" aria-current="page" :to="{name: 'home', hash: '#'+'dudas'}">Blog Mine Class</router-link>
              </li>
            </ul>
          </div>
          <div class="col-lg-5">
            <div class="d-flex mb-5">
              <img src="../assets/logos/logos_footer/sello-codelco.png" alt="" class="codelco-sello">
              <img src="../assets/logos/logos_footer/compromiso.png" alt="" class="compromiso">
            </div>
          </div>
        </div>
        <div class="text-center py-3">
          <p class="raleway-11 m-0">Hecho con 🧡 para la comunidad minera</p>
        </div>
      </div>
    </footer>
  </div>
  
</template>

<script>
export default {
  name: 'FooterComp',
}
</script>