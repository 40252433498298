import { createStore } from 'vuex'
import slides from './slides.json'
import testimoniosVideos from './testimoniosVideos.json'
import otrosTestimonios from './otrosTestimonios.json'
import logos from './logos.json'
import faqs from './faqs.json'

export default createStore({
  state: {
    slides: slides,
    videos: testimoniosVideos,
    otros: otrosTestimonios,
    logos: logos,
    faqs: faqs,
    activeVideoIndex: null, // Índice del video activo
  },
  getters: {
  },
  mutations: {
    setActiveVideo(state, index) {
      state.activeVideoIndex = index;
    },
  },
  actions: {
  },
  modules: {
  }
})
